import React, { useState } from 'react'
import {startingQuestions} from '../startingQuestions.js'
import {needsBot} from '../needsBot.js'
import {haireQt} from '../hairQt.js';
import {submit, submitBesoins, uploadPictureForHairs} from '../api/service.js'
import {appLanguage, titleNormalized} from "../select";
import Speech from 'speak-tts'
import { replaceDashesAndUnderscores } from '../utils/helper.js';
const speech = new Speech()
if(speech.hasBrowserSupport()) {
  // console.log("speech synthesis supported")
  speech.init({
    'volume': 1,
    'lang': (appLanguage === 'french' ? 'fr-FR' : 'en-US'),
    'rate': 1,
    'pitch': 1,
    'voice':(appLanguage === 'french' ? 'Google français' : ((titleNormalized === 'new-pharma' || titleNormalized === 'chatbot-hair') ? 'Microsoft Mark - English (United States)' : 'Microsoft Zira - English (United States)')),
    'splitSentences': true,
    'listeners': {
      'onvoiceschanged': (voices) => {
        // console.log("Event voiceschanged", voices)
      }
    }
  }).then((data) => {
    }).catch(e => {
        console.error("An error occured while initializing : ", e)
    })
}

const scrollableDiv = document.querySelector('.react-chatbot-kit-chat-message-container');

// Fonction pour faire défiler la div vers le bas
function scrollToBottom() {
  if(scrollableDiv)
  scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
}


const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  scrollToBottom() // ajout pour toujours afficher le bas de la div qui contient les messages 
  //const { actionProvider } = children
  const states = children.props.children.props.state
  const[needsFlag, setNeedsFlag] = useState(false)
  const[hairFlag, setHairFlag] = useState(false)
  const[needQ, setNeedQ] = useState([])
  const[needHairQ, setNeedHaireQ] = useState([])

  const getNextId = (id) => {
    return getQuestion(id).next ?  getQuestion(id).next :  null
  }

  const getQuestion = (id) => {
    return startingQuestions.filter(item => item.uid === id)[0]
  }

  const getQuestionNeed = (array, id) => {
    return array.filter(item => item.id === id)[0]
  }

  const getQuestionHair = (array, uid) => {
    return array.filter(item => item.uid === uid)[0]
  }

  const getNeedsQuestions = (needs) => {
    const keys = [{key: '_BEFORE'}, ...needs, {key: '_AFTER'}];
    const array = [];
    keys.forEach(element => {
      const besoin = element.key
        .replace("PORES", 'GRAIN DE PEAU')
        .replace("REGULATION", 'BRILLANCE')
        .replace("IMPERFECTIONS", 'BOUTONS');

      const subArr = needsBot[besoin].map(q => ({besoin, ...q}));
      array.push(subArr);
    });
    return array.flat();
  }

  const next = (id, needs, additionalText = null) => {


    let message
    let finalValue = null

    // console.log('id question', id)

  let question = getQuestion(id)

  // if(id === 1){
  //   question.question = `Enchanté ${states.fullName}, quel âge as-tu ?`
  // }

    if(id === 25){
      question.question += ` ${states.arg}.`
    }
   
    if(id === 33 && !needsFlag){
      // console.log('finie 1er partie')
      let tmp = getNeedsQuestions(needs.myNeeds)
      // console.log(tmp)
      const question = getQuestionNeed(tmp, 1)
      // console.log(question)
      message = createChatBotMessage(
        question.question,
        {
          payload: { choices : question.choices, uid: id, id: question.id, isSec: true},
          loading: true,
          terminateLoading: true,
          withAvatar: true,
          widget: question.widget || null,
        }
      );
      addMessageToState(message)
      setNeedQ(tmp)
      setNeedsFlag(true)
     
    } else if(needsFlag) {
      let tmp = needQ
      // console.log(tmp, id)

      let question;
      if(id === 2) {
        // const questionToSkip = tmp.find(item => item.next === id);
        question = tmp.shift();
      } else  if(id === -1) {
        // const questionToSkip = tmp.find(item => item.next === id);
        tmp = tmp.filter(item => item.id !== 2);
        question = tmp.shift();
      }
      question = tmp.shift();
      // console.log(question)
      setNeedQ(tmp)

        // if(tmp.length === 0){
        // setNeedsFlag(false)
        // }

      // const form = children.props.children.props.state.messages.filter(f=> f.type === "widget");
      // console.log(form[form.length-1])

        message = createChatBotMessage(
        question.question,
        {
          payload: { choices : question.choices, id: question.id, brief: question.brief, isSec: true, besoin: question.besoin, next: id},
          loading: true,
          terminateLoading: true,
          delay: question.delay || null,
          withAvatar: true,
          widget: question.widget || null,
        }
      );
      setNeedQ(tmp)
      // if(tmp.length === 0)
      //   setNeedsFlag(false)

      addMessageToState(message)
    } else if(id === 45 && !hairFlag){ // todo why first one duplicate ??
      // console.log('finie 1er partie hair')
      let tmp = haireQt;
      // console.log(tmp)
      const question = getQuestionHair(tmp, 0)
      if(question.choices && question.choices.length && !question.widget) {
        question.question += ' (' + question.choices.join(', ') + ')';
      }
      message = createChatBotMessage(
          question.question,
          {
            payload: { choices : question.choices, uid: id, id: question.id, isSec: true},
            loading: true,
            terminateLoading: true,
            withAvatar: true,
            widget: question.widget || null,
          }
      );
      addMessageToState(message)
      setNeedHaireQ(tmp)
      setHairFlag(true)

    } else if(hairFlag) {

      let tmp = needHairQ;
      if(!tmp.length){
        submitBesoins(children.props.children.props.state).then(r => r);
        return;
      }
      let question = tmp.shift();
      if(question.uid === 0) {
        question = tmp.shift();
      }
      setNeedHaireQ(tmp)
      if(question.choices && question.choices.length && !question.widget) {
        question.question += ' (' + question.choices.join(', ') + ')';
      }
        message = createChatBotMessage(
            question.question,
          {
            payload: { choices : question.choices, id: question.uid, brief: question.brief, isSec: true, besoin: question.besoin, next: id},
            loading: true,
            terminateLoading: true,
            delay: question.delay || null,
            withAvatar: true,
            widget: question.widget || null,
          }
      );
      setNeedHaireQ(tmp)
      addMessageToState(message);
    } else if(question) {
      if(question.final){
        finalValue = question.value
        setState(prevState => ({
          ...prevState,
          skinType: finalValue
        }))
        id =  question.next//states.selfie ? question.next : 28
        question = getQuestion(id)

      }

      message = createChatBotMessage(
        question.question,
        {
          payload: { uid: id, id: question.id, next: getNextId(id)},
          loading: true,
          terminateLoading: true,
          withAvatar: true,
          delay: question.delay || null,
          widget: question.widget || null,
        }
      );
      addMessageToState(message)
    }  

    if(id === 33){
      submit(children.props.children.props.state.messages).then(r => r);
    }

    // if(id === 8 && titleNormalized === 'chatbot-hair' ){
    //   uploadPictureForHairs(children.props.children.props.state.messages).then(r => r);
    // }

    
    // console.log(id);
  };

  
  const createBotMessage = (msg, options= {}) => {
    const message = createChatBotMessage(
      msg,
      options
    );
    addMessageToState(message)
  };


  
  // tient a jour le state du bot en ajoutant au state le dernier message recu
  const addMessageToState = (message) => {

    const icon = document.querySelector('.react-chatbot-kit-chat-btn-send-icon');
    const btn = document.querySelector('.react-chatbot-kit-chat-btn-send');
    const loading_point = document.createElement('span');
    const next_point = document.createElement('span');
    loading_point.className = 'loading-points';
    loading_point.append(next_point);
    icon.style.display = 'none';
    btn.append(loading_point);
    setTimeout(() => {
      icon.style.display = 'block';
      loading_point.remove();
    }, 1000);

    let video =  document.getElementById('vid');
    if(message.payload.uid === 0) {
      setTimeout(() => {
        video.style.transform = 'translateY(1000%)';
      }, 12000);
    } else if(message.payload.uid === 4 && titleNormalized !== 'chatbot-hair') {
      video.src = `assets/videos/video-2-${titleNormalized}-${appLanguage}.mp4`;
      video.style.transform = 'translateY(0)';
      video.play();
      setTimeout(() => {
        video.style.transform = 'translateY(1000%)';
      }, 9200)
    } else if(message.payload.uid === 5) {
      video.src = `assets/videos/video-6-${titleNormalized}-${appLanguage}.mp4`;
      video.style.transform = 'translateY(0)';
      video.play();
      setTimeout(() => {
        video.style.transform = 'translateY(1000%)';
      }, 6000)
    } else if(message.payload.id === 8 && titleNormalized === 'chatbot-hair') {
      video.src = `assets/videos/video-7-${titleNormalized}-${appLanguage}.mp4`;
      video.style.transform = 'translateY(0)';
      video.play();
      setTimeout(() => {
        video.style.transform = 'translateY(1000%)';
      }, 5000)
    } else {
      if(!localStorage.getItem('MUTE_TTS') || !JSON.parse(localStorage.getItem('MUTE_TTS'))) {
        setTimeout(() => {
          let msg
          message.payload.choices ? msg = message.message + message.payload.choices.map(item => item.text).join(', ') : msg = message.message
          speech.speak({text: replaceDashesAndUnderscores(msg)});
        }, 500);
      }
    }
    // let video = 'assets/video1.mp4';
    // if(localStorage.getItem('MY_AI_TALKING_FACE')) {
    //   video = localStorage.getItem('MY_AI_TALKING_FACE');
    // }
    // document.querySelector('#ai-video').src = video;
    // setTimeout(() => {
    //   document.querySelector('#vid').src = video;
    // }, 800);
    // speech.speak({text: message.message});
    setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }))
  }

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {next,  createBotMessage},
        });
      })}
    </div>
  );
};

export default ActionProvider;
