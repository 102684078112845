import axios from 'axios'
import { Langage } from "../language";
import { appLanguage, titleNormalized } from "../select";

const base_url = 'https://api.iux1.com/'
const gydtech_url = 'https://cactus.gydtech.io/'
const getText = (key) => {
  return Langage[key][appLanguage]
}

export const uploadPicture = async (image) => {
  const formData = new FormData();
  formData.append('image', image, image.name || 'captured.png');
  const result = await axios({
    method: 'post',
    url: `${base_url}api-v2/picture/upload`,
    data: formData
  });

  return result.data
}

// upload 2 pictures to hairs api
export const uploadPictureForHairs = async (images, uid) => {
  const data = new FormData();
  data.set('hair',  images[0], images[0].name);
  data.set('skull', images[1], images[1].name);

  const result = await axios({
    method: 'put',
    url: `${gydtech_url}api/forms/${uid}/pics`,
    data
  });

  return result
}

export const processAnalysis = async (id) => {

  try {
    const result = await axios({
      method: 'put',
      url: `${base_url}api-v2/picture/${id}/analysis`,
      data: {
        "traitement": {
          "traitementRides": true,
          "traitementRegulation": true,
          "traitementSensibilite": true,
          "traitementEclat": true,
          "traitementHydratation": true,
          "traitementTaches": true,
          "traitementImperfections": true,
          "traitementPores": true
        },
        "age": 52 // todo
      }
    });
    return result.data
  } catch (error) {
    console.error(error)
  }
}

export const submit = async (form) => {

  let final = []
  let tmp = {}
  form.forEach((item, index) => {
    if (item.type === 'bot') {
      if (Object.keys(tmp).length > 0) {
        final.push(tmp)
        tmp = {}
      }
      tmp.question = item.message
      tmp.response = ''
      tmp.brief = null
      tmp.uid = item.id
    }
    else {
      tmp.response = tmp.response.concat("", item.message)
    }

  })

  try {
    const request = {
      session: final,
      version: 2
    }
    const response = await axios.post(`${gydtech_url}api/forms/skin`, request);
    if (response.data.id) {
      // todo stocker le id questoinner 
    }

  } catch (error) {
    console.error(error);
  }
};

export const submitBesoins = async (states) => {
  // console.log(states);
  const form = states.messages;
  let final = [];
  if (titleNormalized !== 'chatbot-hair') {
    final = [
      {
        "question": getText('age_q'),
        "response": states.age,
        "uid": -3,
        isSec: true
      },
      {
        "question": getText('gender_q'),
        "response": states.genre,
        "uid": -2,
        isSec: true
      },
      {
        "question": getText('today_type'),
        "response": states.skinType,
        "uid": -1,
        isSec: true
      }
    ]
  }
  let tmp = {}
  form.forEach((item, index) => {
    if (item.type === 'bot') {
      if (Object.keys(tmp).length > 0) {
        final.push(tmp)
        tmp = {}
      }
      tmp.question = item.message
      tmp.response = ''
      tmp.brief = item.payload.brief || null
      tmp.besoin = item.payload.besoin || '_BEFORE'
      tmp.uid = item.payload.id || item.payload.uid
      tmp.isSec = item.payload.isSec
    }
    else {
      tmp.response = tmp.response.concat("", item.message)
    }

  })
  final = final.sort((a, b) => a.uid - b.uid);
  final = final.filter(f => f.isSec);
  final = final.map(f => {
    delete f.isSec;
    if (f.brief === null) {
      delete f.brief;
    }
    if (titleNormalized === 'chatbot-hair') {
      delete f.besoin;
    }
    return f;
  });

  try {
    const request = {
      session: final.filter(f => f.uid !== 16),
      version: 2
    }
    let endpoint = 'besoins';
    if (titleNormalized === 'chatbot-hair') 
      endpoint = 'hair';
    let response
    response = await axios.post(`${gydtech_url}api/forms/${endpoint}`, request); 
    if (titleNormalized === 'chatbot-hair') {
      await uploadPictureForHairs(states.imgSelfie, response.data.id);
    }
    if (response.data.id) {
      window.location.href = `https://democactus.gydtech.io?mail=${states.email}&idQuestionner=${response.data.id}&token=${states.token}&pharmaId=1886715`;
    }

  } catch (error) {
    console.error(error);
  }
};

export const getQuestions = async (type = '') => {

  try {
    return await axios({
      method: 'get',
      url: `${gydtech_url}api/questions${type}`
    })
  } catch (error) {
    console.error(error)
  }

}

export const skinType = async (scores, age) => {

  try {
    const result = await axios({
      method: 'post',
      url: `${gydtech_url}api/skintype`,
      data: { scores, age }
    });

    return result.data
  } catch (error) {
    console.error(error)
  }

}

export const registerProfile = async (data) => {

  try {
    const result = await axios({
      method: 'post',
      url: `https://api.effimetrix.care/profiles`,
      data: data
    });

    return result.data
  } catch (error) {
    console.error(error)
  }

}

export const findProfileByEmail = async (email) => {

  try {
    const result = await axios({
      method: 'get',
      url: `https://api.effimetrix.care/profiles/find/${email}`
    });

    return result.data
  } catch (error) {
    console.error(error)
  }

}
// retoit le token d un profile en lui enviyant son id
export const getToken = async (id) => {

  try {
    const result = await axios({
      method: 'post',
      url: `https://api.effimetrix.care/customers/token/${id}`
    });

    return result.data
  } catch (error) {
    console.error(error)
  }

}


/////////////////////////
// AI D-ID FACE & TALK //
/////////////////////////

const baseUrlDid = 'https://api.d-id.com';
const D_ID_API_TOKEN = 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik53ek53TmV1R3ptcFZTQjNVZ0J4ZyJ9.eyJodHRwczovL2QtaWQuY29tL2ZlYXR1cmVzIjoidGFsa3MiLCJodHRwczovL2QtaWQuY29tL2N4X2xvZ2ljX2lkIjoiIiwiaXNzIjoiaHR0cHM6Ly9hdXRoLmQtaWQuY29tLyIsInN1YiI6Imdvb2dsZS1vYXV0aDJ8MTA1ODQ3NzUwODQyOTg2ODE0OTUyIiwiYXVkIjpbImh0dHBzOi8vZC1pZC51cy5hdXRoMC5jb20vYXBpL3YyLyIsImh0dHBzOi8vZC1pZC51cy5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNjg3NzE0MDU4LCJleHAiOjE2ODc4MDA0NTgsImF6cCI6Ikd6ck5JMU9yZTlGTTNFZURSZjNtM3ozVFN3MEpsUllxIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCByZWFkOmN1cnJlbnRfdXNlciB1cGRhdGU6Y3VycmVudF91c2VyX21ldGFkYXRhIG9mZmxpbmVfYWNjZXNzIn0.AVE6GK_VYHxh7M_r3cRpECy9aj9N_TsVMuWO82OsfPK1K41Nj6MXQp0859h6UUFGiOewKcm9UjN0Tv4kws4s-khxVTwlReb0BPEdKAXS1OBAGZ-VMm8wMLQPp83DaWlFRMpevhnySI_jsZkTmzOZEaLH3hBrQ1IrTrZvrkYHbEAng5EDDJv9rayHfi9O-RssoBjIBDbRLzGMz0hqzOWYnXfT4uTsbTSsDE5vHstkmmsZkvaKO6Y4ZWfgoAx2RIfuF1VxGMmhdt-reWcvEh-dFzpYdPRn5o1hg-1Y6zOMadcuD4rrQD0Dt-xTqh5MoSBnayJoW1_HHyAGabLfeCe7gA';

export const uploadPictureD_id = async (image) => {
  const formData = new FormData();
  formData.append('image', image, image.name || 'captured.png');
  const result = await axios({
    method: 'POST',
    url: `${baseUrlDid}/images`,
    headers: {
      accept: 'application/json',
      'content-type': 'multipart/form-data',
      authorization: D_ID_API_TOKEN
    },
    data: formData
  });

  if (result.data && result.data.url) {
    localStorage.setItem('MY_AI_PIC', result.data.url);
  }

  return create_a_talk();
}

export const create_a_talk = async () => {
  const result = await axios({
    method: 'POST',
    url: `${baseUrlDid}/talks`,
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: D_ID_API_TOKEN
    },
    data: {
      script: {
        type: 'text',
        subtitles: 'false',
        provider: { type: 'microsoft', voice_id: 'fr-FR-DeniseNeural' },
        ssml: 'false',
        input: 'Enchanté, Quel âge avez-vous ?'
      },
      config: { fluent: 'false', pad_audio: '0.0' },
      source_url: localStorage.getItem('MY_AI_PIC')
    }
  });

  if (result.data && result.data.id) {
    localStorage.setItem('MY_AI_ID', result.data.id);
  }

  return get_a_talk();
}

export const get_a_talk = async () => {
  const result = await axios({
    method: 'GET',
    url: `${baseUrlDid}/talks/${localStorage.getItem('MY_AI_ID')}`,
    headers: {
      accept: 'application/json',
      authorization: D_ID_API_TOKEN
    }
  });

  if (result.data && result.data.result_url) {
    localStorage.setItem('MY_AI_TALKING_FACE', result.data.result_url);
    document.querySelector('#ai-video').src = localStorage.getItem('MY_AI_TALKING_FACE');
    return result.data;
  } else {
    setTimeout(() => {
      return get_a_talk();
    }, 3000)
  }
}
