import React from 'react'
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import './videoIntro.css'
import {Langage} from "../../language";
import {appLanguage, titleNormalized} from "../../select";
import {Box} from "@mui/material";

let showDisableLecture = false;
const getText = (key) => {
    return Langage[key][appLanguage];
}

const muteTextToSpeech = () => {
    localStorage.setItem('MUTE_TTS', 'true');
}

const activateTextToSpeech = () => {
    localStorage.setItem('MUTE_TTS', 'false');
}

const VideoIntro = (props) => {

    return (
        <>
            <Button onClick={()=> {const video =  document.getElementById('vid'); video.style.transform = 'translateY(0)'; video.play(); activateTextToSpeech(); showDisableLecture = true; props.actionProvider.next(0) }}
                    variant="contained" className={'btn-black'} endIcon={<DoneIcon />}>
                {getText('i_accept')}
            </Button>
            
            <video style={{margin:"auto"}} playsInline id="vid">
                <source src={`/assets/videos/intro-${titleNormalized}-${appLanguage}.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <Box>
                {
                    showDisableLecture && <Button variant="contained" className={'btn-black'} onClick={()=> { muteTextToSpeech() }}>
                        {getText('disable_lecture')}
                    </Button>
                }
            </Box>
        </>
    )
}

export default VideoIntro
