import React, { useState } from 'react'
import { Button, Box } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import TextsmsIcon from '@mui/icons-material/Textsms';
import './selfieOrChat.css'
import { uploadPicture, processAnalysis, skinType } from '../../api/service.js'
import { saveResWidget } from '../../utils/utils';
import { sortScoreArray } from '../../utils/helper';
import Pub from '../../components/pub/pub';
import { Langage } from "../../language";
import { appLanguage, titleNormalized } from "../../select";


const SelfieOrChat = (props) => {
    const { setState } = props

    const [takePic1, setTakePic1] = useState(false); // verifie si la photo 1 a etet rpis dans cactus hair
    const [openPub, setOpenPub] = useState(false);
    // recoit les scores et les formattes de la bonne facon pour les envoyer a l api
    const getText = (key) => {
        return Langage[key][appLanguage]
    }

    const extractScore = (arr) => {
        let scores = {}
        Object.keys(arr).forEach((key) => scores[key] = arr[key].final)
        return scores
    }

    const handleCapture = async (target) => {

        if (target.files && titleNormalized === 'chatbot-hair') {
            if (target.files.length !== 0) {
                const file = target.files[0];
                // const newUrl = URL.createObjectURL(file);
                // setSource(newUrl);
                const tmp = { "imgSelfie": [...props.state.imgSelfie, file] }
                props.setState((prev) => {
                    return { ...prev, ...tmp }
                });
            }
            if (takePic1)
                props.actionProvider.next(5);

            setTakePic1(true)
            return;
        }

        else if (target.files && titleNormalized !== 'chatbot-hair') {
            if (target.files.length !== 0) {
                const file = target.files[0];
                // const newUrl = URL.createObjectURL(file);
                // setSource(newUrl);
                const tmp = { "imgSelfie": file }
                props.setState((prev) => {
                    return { ...prev, ...tmp }
                });
                await postPicture(file)
            }
        }
        // faire un post de la photo pour aue l api puisse rendre le type de peau en question et le stocker dans le state typeSkin
    }

    const postPicture = async (image) => {
        setOpenPub(true);
        try {
            let result
            result = await uploadPicture(image)
            if (result.success) {
                const uid = result.uid
                const analysis = await processAnalysis(uid)
                const scores = extractScore(analysis.scores)
                const res = await skinType(scores, 50);
                const tmp = {
                    uid: uid,
                    analysis: analysis,
                    needs: sortScoreArray(analysis.scores),
                    myNeeds: sortScoreArray(analysis.scores).slice(-3),
                    skinType: res.skintype,
                    arg: res.arguments,
                    open: openPub
                }
                setOpenPub(false);
                props.actionProvider.next(5);
                props.setState((prev) => {
                    return { ...prev, ...tmp }
                });
            }
        } catch (err) {
            setOpenPub(false);
            console.error(err);
        }
    };

    return (<div className="btns">
        <Button component="label" variant="contained" startIcon={<PhotoCameraIcon />}>
            {titleNormalized === 'chatbot-hair' ? getText('take_selfie_hair') : getText('take_selfie')}
            <input hidden
                accept="image/*"
                type="file"
                capture="environment"
                onChange={(e) => {
                    saveResWidget('Prendre mon selfie', setState); setState((prev) => {
                        return { ...prev, ...{ selfie: true } }
                    }); handleCapture(e.target)
                }} />
        </Button>
        {/* demande une deuxime photo du crane si on est dans cactus hair */}
        {takePic1 && <Button component="label" variant="contained" startIcon={<PhotoCameraIcon />}>
            {getText('take_selfie_scalp')}
            <input hidden
                accept="image/*"
                type="file"
                capture="environment"
                onChange={(e) => {
                    saveResWidget('Prendre mon selfie', setState); setState((prev) => {
                        return { ...prev, ...{ selfie: true } }
                    }); handleCapture(e.target)
                }} />
        </Button>}
        {/*<Button onClick={ () => {saveResWidget('Démarrer le chat', setState); setState((prev) => {*/}
        {/*            return { ...prev, ...{selfie: false} } */}
        {/*       });props.actionProvider.next(5) }} variant="contained" startIcon={<TextsmsIcon />}>*/}
        {/*    Démarrer le chat*/}
        {/*</Button>*/}
        {/*{source &&*/}
        {/*    <Box display="flex" justifyContent="center">*/}
        {/*      <img height="250" src={source} alt={"snap"}></img>*/}
        {/*    </Box>}*/}
        <Pub open={openPub}>
        </Pub>
    </div >)
}

export default SelfieOrChat
